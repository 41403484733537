export class LocalStorageKeys {
  static eva_web_access_token = "eva_web_access_token";
  static eva_web_event_data = "eva_web_event_data";
  static eva_user_event_id = "eva_user_event_id";
  static user = "user";
  static eva_web_event_user_obj = "eva_web_event_user_obj";
  // static mylo_user_access_token = "mylo_user_access_token";
  // static mylo_user_question_id ="mylo_user_question_id"
}

export class QuestionStatus {
  static DRAFT = "draft";
  static FINALIZED = "finalized";
  static PENDING = "pending";
  static INCOMING = "incoming";
  static ACCEPTED = "accepted";
  static ACTIVE = "active";
  static REJECTED = "rejected";
  static UNANSWERED = "unanswered";
  static INAPPROPRIATE = "INAPPROPRIATE";
  static CANCELLED = "cancelled";
}
