import { CircularProgress } from "@mui/material";
import React from "react";
import "./LoadingWithText.scss";

const LoadingWithText = () => {
  return (
    <div className="loading_with_text_main_container">
      <CircularProgress style={{ color: "black" }} />
      <p>Loading...</p>
    </div>
  );
};

export default LoadingWithText;
