// import "./Exhibitors.scss";
import InfoSideBar from "../../infoSideBar/InfoSideBar";
import Layout from "../../Layout";
import ExhibitorsList from "./ExhibitorsList";
import EventBox from "../../event/EventBox";
import "../../common.scss";

const Exhibitors = () => {
  return (
    <>
      <Layout showFixedFooter={true}>
        <InfoSideBar />
        <div className="main-content-card">
          <div className="event-baner-mdiv">
            <div className="event-baner-mobile-section">
            <EventBox showEventNameDiv={true} />
              <h3 className="event-page-title">Exhibitors</h3>
              <ExhibitorsList />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Exhibitors;
