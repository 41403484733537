import { CircularProgress } from "@material-ui/core";
import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { passwordAction } from "../../../redux/features/passwordSlice";
import { RootState } from "../../../redux/store";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./ChangePasswordModal.scss";
import ResponseCodes from "../../../utils/response-codes";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { LoginApi } from "../../../utils/api/user/LoginApi";

const initialValues = {
  current_password: "",
  new_password: "",
  confirm_password: "",
};
const ChangePasswordModal = () => {
  const [state, setState] = useState({
    isLoading: false,
    showPassword: false,
    currentShowPassword: false,
    newShowPassword: false,
    confirmShowPassword: false,
    passwordMatch: false,
  });
  const { event_id } = useParams();
  const dispatch = useDispatch();
  let changePasswordModal = useSelector(
    (state: RootState) => state?.password?.changePasswordModal
  );

  const handleClose = () => {
    formik.resetForm();
    dispatch(passwordAction?.setChangePasswordModal());
  };

  const onClickPasswordToggle = (type: string) => {
    switch (type) {
      case "current":
        setState({
          ...state,
          currentShowPassword: !state?.currentShowPassword,
        });

        break;
      case "new":
        setState({
          ...state,
          newShowPassword: !state?.newShowPassword,
        });

        break;
      case "confirm":
        setState({
          ...state,
          confirmShowPassword: !state?.confirmShowPassword,
        });

        break;
    }
  };

  const changePasswordApi = async (values: any) => {
    setState({
      ...state,
      isLoading: true,
    });
    let res = await LoginApi.changePassword({
      new_password: values?.new_password,
      old_password: values?.current_password,
      event_id,
    });
    switch (res.response_code) {
      case ResponseCodes.UPDATE_SUCCESS:
        toast.success("Password update successfully");
        handleClose();
        break;
      case ResponseCodes.FAILED:
        toast.error(res?.data?.message);
        break;
      case ResponseCodes.NOT_FOUND:
        toast.error(res?.data?.message);
        break;
      default:
        toast.error(res?.data?.message || "Internal server error!");
    }
    setState({
      ...state,
      isLoading: false,
    });
  };

  const onSubmitHandler = async (values: any) => {
    if (values?.new_password === values?.confirm_password) {
      changePasswordApi(values);
    } else {
      setState({
        ...state,
        passwordMatch: true,
      });

      setTimeout(() => {
        setState({
          ...state,
          passwordMatch: false,
        });
      }, 2000);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      current_password: Yup.string().required("Please enter your password!"),
      new_password: Yup.string().required("Please enter your password!").matches(/^\S*$/, "Whitespaces are not allowed!"),
      confirm_password: Yup.string().required("Please enter your password!").matches(/^\S*$/, "Whitespaces are not allowed!"),
    }),
    onSubmit: onSubmitHandler,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const handlePasswordChange = (e: any) => {
    formik.setFieldValue(e.target.name, (e.target.value).replaceAll(' ', ''));
  }

  return (
    <>
      <Dialog
        open={changePasswordModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
        fullWidth={true}
        maxWidth="sm"
      >
        <div className="change_password_container">
          <div className="change_password_modal_title">
            <p>Change Password</p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="password_input_and_lable">
              <label htmlFor="" className="label">
                Current Password
              </label>
              <div className="password_input">
                <input
                  name="current_password"
                  value={formik?.values?.current_password}
                  placeholder="Enter your password"
                  type={state?.currentShowPassword ? "text" : "password"}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handlePasswordChange(e);
                  }}
                />

                <div
                  className="password_show_and_hide"
                  onClick={() => onClickPasswordToggle("current")}
                >
                  {state?.currentShowPassword ? <IoMdEye /> : <IoMdEyeOff />}
                </div>
              </div>
              {formik?.errors?.current_password && (
                <div className="error_message">
                  {formik?.errors?.current_password}
                </div>
              )}
            </div>

            <div className="password_input_and_lable">
              <label htmlFor="" className="label">
                New Password
              </label>
              <div className="password_input">
                <input
                  placeholder="Enter your password"
                  type={state?.newShowPassword ? "text" : "password"}
                  onChange={formik.handleChange}
                  name="new_password"
                  value={formik?.values?.new_password}
                />

                <div
                  className="password_show_and_hide"
                  onClick={() => onClickPasswordToggle("new")}
                >
                  {state?.newShowPassword ? <IoMdEye /> : <IoMdEyeOff />}
                </div>
              </div>
              {formik?.errors?.new_password && (
                <div className="error_message">
                  {formik?.errors?.new_password}
                </div>
              )}
            </div>

            <div className="password_input_and_lable">
              <label htmlFor="" className="label">
                Confirm New Password
              </label>
              <div className="password_input">
                <input
                  placeholder="Enter your password"
                  type={state?.confirmShowPassword ? "text" : "password"}
                  onChange={formik.handleChange}
                  name="confirm_password"
                  value={formik?.values?.confirm_password}
                />

                <div
                  className="password_show_and_hide"
                  onClick={() => onClickPasswordToggle("confirm")}
                >
                  {state?.confirmShowPassword ? <IoMdEye /> : <IoMdEyeOff />}
                </div>
              </div>

              <div className="error_message">
                {state.passwordMatch
                  ? "Passwords don't match"
                  : formik?.errors?.confirm_password}
              </div>
            </div>
            <div className="btn_container">
              <button
                className="cancle_button"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button className="submit_button" type="submit">
                {state?.isLoading ? (
                  <CircularProgress style={{ width: 30, height: 30 }} />
                ) : (
                  "Change password"
                )}
              </button>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default ChangePasswordModal;
