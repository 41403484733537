import { Link, useParams } from "react-router-dom";
import "./common.scss";

const FixedFooter = () => {

    let params = useParams();
    const eventId = params?.event_id;

    return (
        <div className="fixed-menu-div">
            <Link to={`/event/${eventId}/dashboard`} className="fixed-menu-link" title="Home">
                <img src="/static/images/icons/home.svg" alt="Home" />
                <p className="fixed-menu-name">Home</p>
            </Link>
            <Link to={`/event/${eventId}/conference-agenda`} className="fixed-menu-link" title="Agenda">
                <img src="/static/images/icons/agenda.svg" alt="Agenda" />
                <p className="fixed-menu-name">Agenda</p>
            </Link>
            <Link to={`/event/${eventId}/1-1-meetings`} className="fixed-menu-link" title="1 2 1  Meeting">
                <img src="/static/images/icons/meetings.svg" alt="Meeting" />
                <p className="fixed-menu-name">1 2 1  Meeting</p>
            </Link>
            <Link to={`/event/${eventId}/1-1-meetings/networking-events`} className="fixed-menu-link" title="Networking">
                <img src="/static/images/icons/networking.svg" alt="Networking" />
                <p className="fixed-menu-name">Networking</p>
            </Link>
        </div>
    )
}

export default FixedFooter;