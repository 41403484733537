import "./Header.scss";

import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import LoginProfile from "../loginProfile/LoginProfile";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { EnvironmentVariables } from "../../enums/env.enums";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Skeleton } from "@mui/material";
import { FiUser } from "react-icons/fi";
import { RiArrowDropRightLine } from "react-icons/ri";
import { UserDetails, getImageUrl } from "../userDetails/userDetails";
import { LocalStorageHelpers } from "../helpers/localstorage.helpers";
import { AuthHelpers } from "../helpers/auth.helpers";
import { Helmet } from 'react-helmet-async';
import { usersListAction } from "../../redux/features/users/usersListSlice";
import { CommanEnums } from "../enums/comman.enums";

const Header = () => {
  const { eventData } = useSelector((state: RootState) => state?.eventData);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  let params = useParams();
  const eventId = params?.event_id ?? EnvironmentVariables.homePageEventId;
  let pathnameArray = location?.pathname?.split("/");
  let loginCheck = pathnameArray[3];
  const dispatch = useDispatch();

  let isLoggedIn = AuthHelpers?.validateAccessToken();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  let storeUserData: any = useSelector(
    (state: RootState) => state?.usersList?.userData
  );

  useEffect(() => {
    if (isLoggedIn)
      if (!storeUserData) {
        dispatch(usersListAction?.fetchUser());
      }
  }, []);

  const navLinks = [
    // { to: "/", text: "Home" },
    { to: `/event/${eventId}/dashboard`, text: "Home" },
    { to: `/event/${eventId}/sponsors`, text: "Sponsors" },
    { to: `/event/${eventId}/exhibitors`, text: "Exhibitors" },
    { to: `/event/${eventId}/1-1-meetings/view-schedule`, text: "Schedule" },
    { to: `/event/${eventId}/conference-agenda`, text: "Conference Agenda" },
    { to: `/event/${eventId}/delegates`, text: "Delegates" },
    { to: `/event/${eventId}/helpdesk`, text: "Help Desk" },

  ];

  const sidebarLinks = [
    /* { to: "/", text: "Home" } , */
    { to: `/event/${eventId}/dashboard`, text: "Home" },
    { to: `/event/${eventId}/sponsors`, text: "Sponsors" },
    { to: `/event/${eventId}/exhibitors`, text: "Exhibitors" },
    { to: `/event/${eventId}/1-1-meetings/view-schedule`, text: "Schedule" },
    { to: `/event/${eventId}/conference-agenda`, text: "Conference Agenda" },
    { to: `/event/${eventId}/delegates`, text: "Delegates" },
    { to: `/event/${eventId}/helpdesk`, text: "Help Desk" },
  ];


  const exhibitionLinks = [
    { text: "General Information", to: `/event/${eventId}/exhibitor-information/general-information`, },
    { text: "FAQs", to: `/event/${eventId}/exhibitor-information/faqs`, },
    { text: "Exhibition Information", to: `/event/${eventId}/exhibitor-information/exhibition-stand-information`, },
    { text: "Additional Orders", to: `/event/${eventId}/exhibitor-information/additional-orders`, },
    { text: "Shipping Information", to: `/event/${eventId}/exhibitor-information/shipping-information`, },
    { text: "Exhibitor Insurance", to: `/event/${eventId}/exhibitor-information/exhibitor-insurance`, },
    // {text: "Product Demos",to: `/event/${eventId}/exhibitor-information/product-demos`,},
    { text: "Parking", to: `/event/${eventId}/exhibitor-information/parking`, },
    { text: "Deadlines", to: `/event/${eventId}/exhibitor-information/deadlines`, },
    // {text: "Raising Your Profile",to: `/event/${eventId}/exhibitor-information/raising-your-profile`,},
    { text: "Marketing Graphics", to: `/event/${eventId}/exhibitor-information/marketing-graphics`, },
  ]

  const delegatesLinks = [
    { text: "My Profile", to: `/event/${eventId}/user-profile`, },
    { text: "My Schedule", to: `/event/${eventId}/1-1-meetings/view-schedule`, },
    { text: "Pending Meetings", to: `/event/${eventId}/1-1-meetings/request-accept-meetings?requested=user`, },
    { text: "One-to-One Meetings", to: `/event/${eventId}/1-1-meetings`, },
    { text: "View All Delegates", to: `/event/${eventId}/delegates`, },
    { text: "Networking Events", to: `/event/${eventId}/1-1-meetings/networking-events`, },
  ];

  useEffect(() => {
    if (eventData?.event_logo) {
      document.querySelectorAll('link[rel="icon"]').forEach(e => {
        if (!e.getAttribute('data-rh')) {
          e.remove();
        }
      })
    }
  }, [eventData?.event_logo]);

  const renderLogo = () => {
    if (eventData?.loading) {
      return (
        <Skeleton
          animation="wave"
          variant="rounded"
          width={210}
          height={60}
          className="header-logo-loader"
        />
      );
    }

    let logo = eventData?.eventData?.event_logo;

    if (logo) {
      let logoUrl = getImageUrl(logo);

      return (
        <div className="event-logo-container">
          <img src={logoUrl} alt="" className="event-logo" />
        </div>
      );
    }

    return (
      <img
        src="/static/images/eva-events-logo-linear.png"
        alt=""
        className="eva-logo"
      />
    );
  };

  const renderBottomNavigationLinks = () => {
    let links: any = [];

    const userData = LocalStorageHelpers.getUserData();

    const userType = userData?.user_type;

    if (userType === CommanEnums.users.exhibitor) {
      links.push(
        <>
          <h3 className="extra-menu-title"> <img src="/static/images/right-arrow.png" alt="right-arrow" className="extra-menu-icon" /> For Exhibitors</h3>
          <ul className="extra-menu-ul">
            {exhibitionLinks.map((link, index) => (
              <li key={index}>
                <Link
                  to={link.to}
                  className={
                    location.pathname === link.to ? "active extra-menu-ul" : " extra-menu-ul"
                  }
                >
                  - {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </>
      )
    } else if (userType === CommanEnums.users.delegate) {
      links.push(
        <>
          <h3 className="extra-menu-title"> <img src="/static/images/right-arrow.png" alt="right-arrow" className="extra-menu-icon" /> For Delegates</h3>
          <ul className="extra-menu-ul">
            {delegatesLinks.map((link, index) => (
              <li key={index}>
                <Link
                  to={link.to}
                  className={
                    location.pathname === link.to ? "active extra-menu-ul" : " extra-menu-ul"
                  }
                >
                  - {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </>
      )
    }
    return links;
  };

  return (
    <>
      {eventData?.event_logo && (
        <Helmet>
          <link rel="icon" href={getImageUrl(eventData.event_logo)} />
        </Helmet>
      )}
      <header className={`header ${isSidebarOpen ? "sidebar-open" : ""}`}>
        <div className="container header_mdiv">
          <div className="login_web">
            {loginCheck === "login" ? "" : <LoginProfile />}
          </div>


          <div className="header_container">
            <Link to={`/event/${eventId}/dashboard`}>
              <div className="logo_cont">{renderLogo()}</div>
            </Link>
            {isLoggedIn && (
              <div className="header_menu">
                <ul>
                  {navLinks.map((link, index) => (
                    <li key={index}>
                      <Link
                        to={link.to}
                        className={
                          location.pathname === link.to ? "pointer_none" : ""
                        }
                      >
                        <a
                          className={
                            location.pathname === link.to ? "active" : ""
                          }
                        >
                          {link.text}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="responsive_view">
            <div className="menu_burger_icon" onClick={handleSidebarToggle}>
              {isLoggedIn && (
                <img src="/static/images/menu-burger.svg" alt="" />
              )}
            </div>
            <Link to={`/event/${eventId}/dashboard`}>
              <div className="event_logo_cont">{renderLogo()}</div>
            </Link>
            <div className="login_mobile">
              {loginCheck === "login" ? "" : <LoginProfile />}
            </div>
          </div>
        </div>
        <>
          {isLoggedIn && (
            <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
              <div className="sidebar_header">
                <div className="cancel_icon" onClick={handleSidebarToggle}>
                  <img src="/static/images/cancel.svg" alt="" />
                </div>
                <div className="event_logo">
                  <img src="/static/images/eva-events-logo-linear.png" alt="" />
                </div>
              </div>
              <div className="sidebar_menu">
                <div className="sidebar-username-div"><FiUser width="15px" height="15px" /> <span>Welcome, {UserDetails?.getUserName(storeUserData)}</span></div>
                <ul>
                  {sidebarLinks.map((link, index) => (
                    <li key={index}>
                      <Link
                        to={link.to}
                        className={
                          location.pathname === link.to ? "active" : ""
                        }
                      >
                        {link.text}
                      </Link>
                    </li>
                  ))}
                </ul>
                {renderBottomNavigationLinks()}
              </div>
            </div>
          )}
        </>
      </header>
    </>
  );
};

export default Header;
