import { useEffect, useState } from "react";
import "./EventBox.scss";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { eventDataAction } from "../../redux/features/eventDataSlice";
import { useLocation, useParams } from "react-router-dom";
import { getImageUrl } from "../userDetails/userDetails";
import { EnvironmentVariables } from "../../enums/env.enums";
import { FiCalendar } from "react-icons/fi";
import { SlClock, SlLocationPin } from "react-icons/sl";
import moment from "moment";

const EventBox = ({
  showEventNameDiv
}: {
  showEventNameDiv: boolean;
}) => {
  const dispatch = useDispatch();
  let params = useParams();
  let location = useLocation();
  const eventId = params?.event_id ?? EnvironmentVariables.homePageEventId;
  let { eventData, loading }: any = useSelector((state: RootState) => state?.eventData);
  let pathnameArray = location?.pathname?.split("/");
  let loginCheck = pathnameArray[3];

  useEffect(() => {
    if (eventId !== "undefined") {
      if (pathnameArray[3] === "login" || eventData === null) {
        dispatch(eventDataAction.fetchEvent({ event_id: eventId }));
      }
    }
  }, []);

  const [eventLogo, setEventLogo] = useState(
    <img
      src="/static/images/eva-events-logo-linear.png"
      alt=""
      className="card_logo"
    />
  );

  useEffect(() => {
    let logo = eventData?.event_logo;
    if (logo) {
      setEventLogo(
        <img src={getImageUrl(logo)} alt="logo" className="card_logo" />
      );
    }
  }, [eventData?.event_logo]);

  const getDate = (startDate: string, endDate: string) => {
    let startDateObj = moment.utc(startDate).format("MMMM Do").split(" ");
    let endDateObj = endDate ? moment.utc(endDate).format("MMMM Do YYYY").split(" ") : null;

    let startYear = new Date(startDate).getUTCFullYear();
    let formattedStartDate = `${startDateObj[1]} ${startDateObj[0]}`;

    if (endDateObj) {
      let endYear = new Date(endDate).getUTCFullYear();
      if (startYear === endYear) {
        return `${formattedStartDate} - ${endDateObj[1]} ${endDateObj[0]} ${endYear}`;
      } else {
        return `${formattedStartDate} ${startYear} - ${endDateObj[1]} ${endDateObj[0]} ${endYear}`;
      }
    }

    return `${formattedStartDate} ${startYear}`;
  };


  const getTime = (startTime: string, endTime: string) => {
    let momentStartTime = moment(startTime, 'HH:mm:ss').format('hh:mm A');
    let momentEndTime = moment(endTime, 'HH:mm:ss').format('hh:mm A');

    return (momentStartTime + (endTime ? (' - ' + momentEndTime) : ''));
  }

  return (
    <>
      {eventId == "undefined" ? (
        ""
      ) : (
        showEventNameDiv ? (
          loading ? (
            <Skeleton width={"100%"} height="90px" />
          ) : (
            <div className="location_detail_mdiv">
              <h2 className="location_detail_title">{`${eventData?.name} ${(eventData?.start_date ? new Date(eventData?.start_date).getFullYear() : '')}`}</h2>
              <div className="event_box_location_detail">
                <div className="event_box_location_item">
                  <p className="event_box_location_content event-date"><FiCalendar className="social_icon" />{getDate(eventData?.start_date, eventData?.end_date)}</p>
                </div>
                <div className="event_box_location_item">
                  <p className="event_box_location_content event-location"><SlLocationPin className="social_icon" />{`${eventData?.venue_city ? eventData?.venue_city + ', ' : ''}${eventData?.venue_country || ''}`}</p>
                </div>
                <div className="event_box_location_item">
                  <p className="event_box_location_content event-time"><SlClock className="social_icon" />{`${getTime(eventData?.start_time, eventData?.end_time)}${(eventData?.time_zone ? ('(Local)') : '')}`}</p>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="event_box_header_container">
            <div
              className={`event_box_banner_container ${loginCheck === "login" ? "login_padding_check" : ""
                }`}
            >
              {loading ? (
                <Skeleton width={"100%"} height="300px" />
              ) : (
                <div className="row">
                  <div className="card_contain">
                    <div className="card_logo_div">
                      {eventLogo}
                    </div>
                    <div className="aviation_image">
                      <img
                        src={getImageUrl(eventData?.featured_image)}
                        alt=""
                      />
                    </div>
                    <div className="card">
                      <div className="card_title">
                        <p>{eventData?.name},</p>
                        <p>{`${eventData?.venue_city || ''} ${(eventData?.start_date ? new Date(eventData?.start_date).getFullYear() : '')}`}</p>
                      </div>
                    </div>
                  </div>
                  <div className="event_box_location_detail">
                    <div className="event_box_location_item">
                      <p className="event_box_location_content event-date"><FiCalendar className="social_icon" />{getDate(eventData?.start_date, eventData?.end_date)}</p>
                    </div>
                    <div className="event_box_location_item">
                      <p className="event_box_location_content event-location"><SlLocationPin className="social_icon" />{`${eventData?.venue_city ? eventData?.venue_city + ', ' : ''}${eventData?.venue_country || ''}`}</p>
                    </div>
                    <div className="event_box_location_item">
                      <p className="event_box_location_content event-time"><SlClock className="social_icon" />{`${getTime(eventData?.start_time, eventData?.end_time)}${(eventData?.time_zone ? ('(Local)') : '')}`}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default EventBox;
