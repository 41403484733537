import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import NotificationPoller from './components/Notification/NotificationPoller';
import * as Sentry from "@sentry/react";

if(process.env.PRODUCTION_ENV == 'true') {
	Sentry.init({
	  environment: "production",
	  dsn: "https://5e61a2082e0a7090d21f0eaec1589def@o4508086478110720.ingest.de.sentry.io/4508086480207952",
	  integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.browserProfilingIntegration(),
		Sentry.replayIntegration(),
	  ],
	  // Tracing
	  tracesSampleRate: 1.0, //  Capture 100% of the transactions
	  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	  // Set profilesSampleRate to 1.0 to profile every transaction.
	  // Since profilesSampleRate is relative to tracesSampleRate,
	  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
	  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
	  // results in 25% of transactions being profiled (0.5*0.5=0.25)
	  profilesSampleRate: 1.0,
	  // Session Replay
	  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (typeof window !== "undefined" && "serviceWorker" in navigator) {
  navigator?.serviceWorker?.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}


root.render(
  <>
    {/* <App /> */}
    <Provider store={store}>
      <NotificationPoller />
      <RouterProvider router={App()} />
      <Toaster />
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
