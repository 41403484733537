import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import "./HotelsList.scss";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { CommonApi } from "../../utils/api/user/CommonApi";
import ResponseCodes from "../../utils/response-codes";
import LoadingWithText from "../others/loading/LoadingWithText";
import EmptyData from "../others/empty/EmptyData";
import { getImageUrl, httpUrlFormate } from "../userDetails/userDetails";

const HotelsList = () => {
  const [state, setState] = useState({
    listData: [],
    isLoading: true,
  });
  let params = useParams();
  const eventId = params?.event_id || "";
  const getLink = (link: string) => {
    let updateLink;

    if (link?.length < 30) {
      updateLink = link;
    } else {
      let result = link?.slice(0, 30);
      updateLink = `${result}...`;
    }
    return updateLink;
  };

  const getListData = async () => {
    setState({
      ...state,
      isLoading: true,
    });

    let res = await CommonApi.getHotels(eventId);

    switch (res?.response_code) {
      case ResponseCodes.GET_SUCCESS:
        setState({
          ...state,
          isLoading: false,
          listData: res?.data?.data?.docs,
        });
        break;
      case ResponseCodes.NOT_FOUND:
        toast.error(res?.data?.message);
        break;

      default:
        toast.error(res?.data?.error || "Internal server error");
    }
  };

  useEffect(() => {
    getListData();
  }, []);

  console.log("listData>>", state.listData);

  return (
    <>
      {state?.isLoading ? (
        <div className="events_page_loading">
          <LoadingWithText />
        </div>
      ) : state?.listData?.length === 0 ? (
        <div className="event_schedule_empty_data">
          <EmptyData />
        </div>
      ) : (
        <>
          <div className="hotels-list-conatiner new-hotels-list">
            <div className="hotels-list">
              {state?.listData?.map((item: any) => (
                <Link
                  className="hotels-list-link"
                  target="_blank"
                  to={httpUrlFormate(item?.hotel_url) || ""}
                >
                  <div className="hotels-list-item">
                    <div className="hotels-image">
                      <img
                        src={getImageUrl(item?.hotel_images[0]?.file_url)}
                        alt=""
                      />
                    </div>

                    <div className="hotels-bottom-info">
                      <p className="name">{item?.hotel_name}</p>
                      <p className="address">
                        {item?.city ? <span>{item.city}</span> : ''}
                        { (item?.city && item?.country ? <br /> : '' )  }
                        {item?.country ? <span>{item.country}</span> : ''}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default HotelsList;
