import React from "react";
import "./EmptyData.scss";

const EmptyData = () => {
  return (
    <div className="empty_data_main_container">
      <div className="empty_data">
        <img src="/static/images/empty-folder.png" alt="" />
        <p className="no_data_para">No Data</p>
      </div>
    </div>
  );
};

export default EmptyData;
