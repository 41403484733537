import { CommanEnums } from "../enums/comman.enums";
import { UsersListType } from "./../type/propsType";

export class UserDetails {
  static getUserName = (data: UsersListType) => {
    let userName;

    if (data?.first_name) {
      userName = `${data?.first_name || ""} ${data?.last_name || ""}`;
    } else {
      userName = `${data?.representative_firstname || ""} ${data?.representative_lastname || ""}`;
    }

    // if (data?.user_type == CommanEnums.userTypes.exhibitor) {
    //   userName = data.exhibitor_name;
    // }

    let updateName;

    if (userName?.length < 20) {
      updateName = userName;
    } else {
      let result = userName?.slice(0, 20);
      updateName = `${result}...`;
    }

    return updateName;
  };

  static getUserBio = (data: UsersListType) => {
    let userBio;

    switch (data?.user_type) {
      case CommanEnums.users.delegate:
        userBio = data?.bio || "";
        break;
      case CommanEnums.users.exhibitor:
        userBio = data?.description || "";
        break;
      case CommanEnums.users.sponsor:
        userBio = data?.sponsor_description || "";
        break;
      case CommanEnums.users.speaker:
        userBio = data?.bio || "";
        break;
      case CommanEnums.users.media_partner:
        userBio = data?.description || "";
        break;
    }

    return userBio;
  };

  static getUserLogo = (data: UsersListType) => {
    let urlString;

    switch (data?.user_type) {
      case CommanEnums.users.delegate:
        urlString = data?.avatar || "";
        break;
      case CommanEnums.users.exhibitor:
        urlString = data?.exhibitor_logo || "";
        break;
      case CommanEnums.users.sponsor:
        urlString = data?.sponsor_logo || "";
        break;
      case CommanEnums.users.speaker:
        urlString = data?.avatar || "";
        break;
      case CommanEnums.users.media_partner:
        urlString = data?.logo || "";
        break;
    }

    return urlString;
  };

  static getUserLinkedinUrl = (data: UsersListType) => {
    let urlString;

    switch (data?.user_type) {
      case CommanEnums.users.delegate:
        urlString = data?.delegate_linkedin || "";
        break;

      case CommanEnums.users.speaker:
        urlString = data?.speaker_linkedin || "";
        break;

      case CommanEnums.users.sponsor:
        urlString = data?.delegate_linkedin || "";
        break;

      case CommanEnums.users.exhibitor:
        urlString = data?.delegate_linkedin || "";
        break;

      case CommanEnums.users.media_partner:
        urlString = data?.delegate_linkedin || "";
        break;

      default:
        urlString = "";
    }

    return urlString;
  };

  static getUserUrl = (data: UsersListType) => {
    let urlString;

    switch (data?.user_type) {
      case CommanEnums.users.delegate:
        urlString = data?.delegate_URL || "";
        break;
      case CommanEnums.users.exhibitor:
        urlString = data?.exhibitor_URL || "";
        break;
      case CommanEnums.users.sponsor:
        urlString = data?.sponsor_URL || "";
        break;
      case CommanEnums.users.speaker:
        urlString = data?.speaker_URL || "";
        break;
      case CommanEnums.users.media_partner:
        urlString = data?.mediapartner_URL || "";
        break;
      default:
        urlString = "";
    }

    return urlString;
  };
}

export const httpUrlFormate = (url: string) => {

  if (url) {
    let updateUrl = "";
    let httpCheck = url?.includes("http");
    let httpsCheck = url?.includes("https");

    if (httpCheck || httpsCheck) {
      // updateUrl = url.replace(/^https?:\/\//, "");
      updateUrl = url;
    } else {
      updateUrl = `http://${url}`;
    }
    return updateUrl;
  } else {
    return ""
  }


};

export const getImageUrl = (image: any) => {
  // let baseURL = 'https://api.eva-events.wolffox.in';
  let baseURL = process.env.REACT_APP_FILES_ENDPOINT;
  if (image) {
    let result = image.includes("https");
    if (result) {
      return image;
    } else {
      const imageUrlArray = image?.split("/");
      let imageUrl = `${baseURL}/${imageUrlArray[1]}/${imageUrlArray[2]}`;
      return imageUrl;
    }
  } else {
    return "";
  }
};
