import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { CommonApi } from "../../../utils/api/user/CommonApi";
import ResponseCodes from "../../../utils/response-codes";
import EmptyData from "../../others/empty/EmptyData";
import LoadingWithText from "../../others/loading/LoadingWithText";
import "./ExhibitorsList.scss";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getImageUrl, httpUrlFormate } from "../../userDetails/userDetails";

const ExhibitorsList = () => {
  const [state, setState] = useState({
    listData: [],
    isLoading: true,
  });
  let params = useParams();
  const eventId = params?.event_id || "";
  const [companies, setCompanies] = useState([] as any);

  const getLink = (link: string) => {
    let updateLink;

    if (link?.length < 30) {
      updateLink = link;
    } else {
      let result = link?.slice(0, 30);
      updateLink = `${result}...`;
    }
    return updateLink;
  };

  const getListData = async () => {
    setState({
      ...state,
      isLoading: true,
    });

    let res = await CommonApi.getExhibitors(eventId);

    switch (res?.response_code) {
      case ResponseCodes.GET_SUCCESS:
        setState({
          ...state,
          isLoading: false,
          listData: res?.data?.data?.docs,
        });
        break;
      case ResponseCodes.NOT_FOUND:
        toast.error(res?.data?.message);
        break;

      default:
        toast.error(res?.data?.error || "Internal server error");
    }
  };

  useEffect(() => {
    getListData();
  }, []);

  useEffect(() => {
    if (!state.isLoading) {
      var companies = state.listData?.map((exhibitor: any) => exhibitor.company);
      companies = companies.reduce((a: any, c: any) => {
        if (c && !a.find((v: any) => v?._id === c?._id)) {
          a.push(c);
        }
        return a;
      }, []);
      setCompanies(companies);
    }
  }, [state]);

  const emptyData = (
    <div className="event_schedule_empty_data">
      <EmptyData />
    </div>
  );

  const exhibitorInfo = (company: any) => {
    return (
      <>
        <div className="top-logo">
          {company?.company_logo ? (
            <img src={getImageUrl(company?.company_logo)} alt="" />
          ) : (
            <div className="exhibitor_demo_logo">
              <AccountCircleIcon />
            </div>
          )}
        </div>
        <div className="head_title">
          <p>{company?.company_name}</p>
        </div>
        {/* <div
          className="info_text"
          dangerouslySetInnerHTML={{
            __html: company?.description || "",
          }}
        ></div> */}
        {/* <div className="head_link">
          {company?.company_URL && (
            getLink(httpUrlFormate(company?.company_URL))
          )}
        </div> */}
      </>
    )
  }

  const getPageInfo = () => {
    if (companies?.length > 0) {
      return (
        <div className="exhibitors-list-conatiner">
          <div className="exhibitors-list new-exhibitors-list">
            {companies?.map((company: any) => (
              <div className="exhibitor-list-item">
                {company?.company_URL ? (
                  <Link to={httpUrlFormate(company?.company_URL)} target="_blank" key={company?._id}>
                    {exhibitorInfo(company)}
                  </Link>
                ) : (
                  <div>
                    {exhibitorInfo(company)}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )
    } else {
      return emptyData
    }
  }

  return (
    <>
      {state?.isLoading ? (
        <div className="events_page_loading">
          <LoadingWithText />
        </div>
      ) : getPageInfo()}
    </>
  );
};

export default ExhibitorsList;
