import { AnyAaaaRecord } from "dns";
import axiosInstance from "./Axios";

export class EventsApiServices {
  static getEvents = async (
    search: string,
    limit: number,
    cancelToken: any
  ) => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: "web/events",
        params: {
          page: 1,
          limit,
          search,
          status: "",
          created_at: "",
        },
        cancelToken: cancelToken.token,
      });

      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static getEventById = async (event_id: any) => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `web/events/${event_id}`,
        params: {
          poster_images: 6,
        },
      });

      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static getExhibitionInfo = async (event_id: string) => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `web/events/exhibition-info/${event_id}`,
      });
      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static getFaqs = async (event_id: string) => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `admins/events/${event_id}/faqs`,
        params: {
          page: 1,
          limit: 1000,
          search: "",
        },
      });
      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static getVenueMap = async (event_id: string) => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `admins/events/${event_id}/venue-map`,
      });
      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };
}

export class HelpdeskApi {
  static addMessage = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: "web/add-helpdesk-message",
        data,
      });

      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };
}
