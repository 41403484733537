import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { passwordAction } from "../../redux/features/passwordSlice";
import { MdKeyboardArrowDown } from "react-icons/md";
import { usersListAction } from "../../redux/features/users/usersListSlice";
import { RootState } from "../../redux/store";
import { AuthHelpers } from "../helpers/auth.helpers";
import { LocalStorageHelpers } from "../helpers/localstorage.helpers";
import ChangePasswordModal from "../password/changePassword/ChangePasswordModal";
import { getImageUrl, UserDetails } from "../userDetails/userDetails";
import "./LoginProfile.scss";

const LoginProfile = () => {
  const [state, setState] = useState({
    loginDropDown: false,
  });
  let isLoggedIn = AuthHelpers?.validateAccessToken();

  const location = useLocation();
  const dispatch = useDispatch();
  let homePageCheck = location.pathname === "/" ? true : false;

  let isLoading: any = useSelector(
    (state: RootState) => state?.usersList?.loading
  );

  let storeUserData: any = LocalStorageHelpers.getUserData();
  let params = useParams();
  const eventId = params?.event_id;

  const totalNotificationCount = useSelector((state: RootState) => state.notification.count);


  const handleLoginClick = () => {
    setState({
      ...state,
      loginDropDown: !state.loginDropDown,
    });
  };

  const logout = () => {
    localStorage.clear();
  };
  const onClickChangePassword = () => {
    dispatch(passwordAction?.setChangePasswordModal());
    handleLoginClick();
  };

  useEffect(() => {
    if (isLoggedIn)
      if (!storeUserData) {
        dispatch(usersListAction?.fetchUser());
      }
  }, []);

  return (
    <>
      {isLoggedIn ? (
        <>
          <ChangePasswordModal />
          <div className="header_user_login_">
            <div className="user_data_container">
              <div className="user_data" onClick={handleLoginClick}>
                {isLoading ? (
                  <Skeleton circle width="30px" height="30px" />
                ) : (
                  <div className="user_image">
                    {UserDetails?.getUserLogo(storeUserData) ? (
                      <img
                        src={getImageUrl(UserDetails?.getUserLogo(storeUserData))}
                        alt={UserDetails?.getUserName(storeUserData)}
                      />
                    ) : (
                      <img src={"/static/images/user.svg"} alt="" />
                    )}
                  </div>
                )}

                <div className="user_name">
                  {isLoading ? (
                    <Skeleton width="100px" height="15px" />
                  ) : (
                    <div className="circle-container">
                      <p>{UserDetails.getUserName(storeUserData as any)}</p>
                      <div className="circle">
                        {totalNotificationCount}
                      </div>
                    </div>
                  )}
                </div>

                <MdKeyboardArrowDown className="arrow" />
              </div>
            </div>

            {state.loginDropDown && (
              <div className="logout_main_box">
                <div className="logout_option">
                  <ul>
                    <li>
                      <Link to={`/event/${eventId}/user-profile`}>
                        <p>Manage my profile</p>
                      </Link>
                    </li>
                    {homePageCheck ? (
                      ""
                    ) : (
                      <li onClick={onClickChangePassword}>
                        <p>Change password</p>
                      </li>
                    )}
                    <li>
                      <Link to={`/event/${eventId}/notification`}>
                        <p>Notifications {`(${totalNotificationCount})`}</p>
                      </Link>
                    </li>
                    <li onClick={logout}>
                      <Link to={`/`}>
                        <p>Logout</p>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="logout_bg" onClick={handleLoginClick}></div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {homePageCheck ? (
            ""
          ) : (
            <div className="header_user_login_">
              <Link to={`/event/${eventId}/login`}>
                <div className="user_data">
                  <div className="user_image">
                    <img src="/static/images/user.svg" alt="" />
                  </div>
                  <div className="user_name">
                    <p>Login</p>
                  </div>
                </div>
              </Link>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default LoginProfile;
