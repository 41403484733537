// import "./Hotels.scss";
import Layout from "../Layout";
import InfoSideBar from "../infoSideBar/InfoSideBar";
import ArrivalDeparture from "./ArrivalDeparture";
import EventBox from "../event/EventBox";
import "../common.scss";
import FixedFooter from "../FixedFooter";
import SponsoredBySlider from "../SponsoredBySlider";

const AirportTransfers = () => {
    return (
        <>
            <Layout showFixedFooter={true} >
                <InfoSideBar />
                <div className="main-content-card">
                    <div className="event-baner-mdiv">
                        <div className="event-baner-mobile-section">
                            <EventBox showEventNameDiv={true} />
                            <h3 className="event-page-title">Airport Transfers</h3>
                            <ArrivalDeparture />
                            <SponsoredBySlider />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default AirportTransfers;
