import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import dayjs from 'dayjs';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./ArrivalDeparture.scss";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button } from "@mui/material";
import { ProfileApiServices } from "../../utils/api/ProfileApiServices";
import { useParams } from "react-router-dom";

const validationSchema = Yup.object({
    noOfPaxArrival: Yup.number().required("No of Pax is required"),
    otherPaxNamesArrival: Yup.string().required("Pax name is required"),
    airportFromArrival: Yup.string().required("Airport is required"),
    arrivalDate: Yup.date().required("Arrival date is required"),
    arrivalTime: Yup.string().required("Arrival time is required"),
    flightNumberArrival: Yup.string().required("Flight number is required"),
    cabinSuitcaseQtyArrival: Yup.number().required("Cabin suitcase qty is required"),
    holdLuggageQtyArrival: Yup.number().required("Hold luggage qty is required"),
    noOfPaxDeparture: Yup.number().required("No of Pax is required"),
    otherPaxNamesDeparture: Yup.string().required("Pax name is required"),
    airportFromDeparture: Yup.string().required("Airport is required"),
    departureDate: Yup.date().required("Departure date is required"),
    departureTime: Yup.string().required("Departure time is required"),
    flightNumberDeparture: Yup.string().required("Flight number is required"),
    cabinSuitcaseQtyDeparture: Yup.number().required("Cabin suitcase qty is required"),
    holdLuggageQtyDeparture: Yup.number().required("Hold luggage qty is required"),
    transferOption: Yup.string().required("Required"),
});

const initialValues = {
    noOfPaxArrival: "",
    otherPaxNamesArrival: "",
    airportFromArrival: "",
    arrivalDate: null,
    arrivalTime: null,
    flightNumberArrival: "",
    cabinSuitcaseQtyArrival: "",
    holdLuggageQtyArrival: "",
    noOfPaxDeparture: "",
    otherPaxNamesDeparture: "",
    airportFromDeparture: "",
    departureDate: null,
    departureTime: null,
    flightNumberDeparture: "",
    cabinSuitcaseQtyDeparture: "",
    holdLuggageQtyDeparture: "",
    transferOption: '',
};

const ArrivalDeparture = () => {
    let params = useParams();
    const eventId = params?.event_id
    const [userId, setUserId] = useState("");
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    const getDelegateData = async () => {
        try {
            const res = await ProfileApiServices.getDelegateId(user?.email);
            if (res?.data?.success) {
                setUserId(res?.data?.delegateData[0]?._id)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDelegateData()
    }, [])

    const handleSubmit = async (values: any, { resetForm }: any) => {
        const formattedDate = dayjs(values.departureDate).format('YYYY-MM-DD HH:mm:ss');
        const formattedTime = dayjs(values.departureTime).format('HH:mm:ss');
        const formattedArrivalDate = dayjs(values.arrivalDate).format('YYYY-MM-DD HH:mm:ss');
        const formattedArrivalTime = dayjs(values.arrivalTime).format('HH:mm:ss');
        const formattedValues = {
            user_id: userId,
            event_id: eventId,
            arrival_no_of_pax: values.noOfPaxArrival,
            departure_no_of_pax: values.noOfPaxDeparture,
            arrival_pax_names: values.otherPaxNamesArrival,
            departure_pax_names: values.otherPaxNamesDeparture,
            arrival_airport_from: values.airportFromArrival,
            departure_airport_from: values.airportFromDeparture,
            arrival_date: formattedArrivalDate,
            departure_date: formattedDate,
            arrival_time: formattedArrivalTime,
            departure_time: formattedTime,
            arrival_flight_no: values.flightNumberArrival,
            departure_flight_no: values.flightNumberDeparture,
            arrival_cabin_qty: values.cabinSuitcaseQtyArrival,
            departure_cabin_qty: values.cabinSuitcaseQtyDeparture,
            arrival_luggage_qty: values.holdLuggageQtyArrival,
            departure_luggage_qty: values.holdLuggageQtyDeparture,
            transferOption: values.transferOption
        };
        try {
            const response = await ProfileApiServices.createAirportTransfer(formattedValues)
            if (response?.data?.success) {
                toast.success(`${response?.data?.message}`);
                resetForm();
            }
            else {
                toast.error(`${response?.data?.message}`);
            }
        } catch (error) {
            toast.error(`${error}`);

        }

    };

    return (

        <>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, handleChange }) => (
                        <Form>

                            <div className="main-div">
                                <p className="heading-text">
                                    Please ensure all information is saved by clicking the <span style={{ color: "red" }}>save button</span> below
                                </p>
                            </div>
                            <div className="table-div">
                                <div className="arrival-main-div">
                                    <div className="arrival-div">
                                        Arrival
                                    </div>
                                    <div className="no-of-pax">
                                        <p style={{ padding: "5px" }}>No of Pax</p>
                                        <FormControl sx={{ width: "50%", height: "30px" }}>
                                            <Field className="paxArrival" name="noOfPaxArrival" as={Select} displayEmpty>
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                                <MenuItem value={6}>6</MenuItem>
                                                <MenuItem value={7}>7</MenuItem>
                                                <MenuItem value={8}>8</MenuItem>
                                                <MenuItem value={9}>9</MenuItem>
                                            </Field>

                                        </FormControl>
                                    </div>
                                    <ErrorMessage name="noOfPaxArrival" component="div" className="error" />
                                    <div className="other-pax">
                                        <p style={{ padding: "5px" }}>Other pax names</p>
                                        <Field
                                            className="arrivalPaxName"
                                            name="otherPaxNamesArrival"
                                            as={TextField}
                                            variant="filled"
                                            InputProps={{
                                                inputProps: {
                                                    style: {
                                                        padding: '5px 10px',
                                                    },
                                                },
                                            }}
                                            sx={{
                                                '& .MuiFilledInput-root': {
                                                    borderBottom: 'none',
                                                },
                                                '& .MuiFilledInput-underline:before': {
                                                    borderBottom: 'none',
                                                },
                                                '& .MuiFilledInput-underline:after': {
                                                    borderBottom: 'none',
                                                },
                                            }}
                                        />

                                    </div>
                                    <ErrorMessage name="otherPaxNamesArrival" component="div" className="error" />
                                    <div className="airport-from">
                                        <p style={{ padding: "5px" }}>Airport from eg:LHR</p>
                                        <Field
                                            className="arrivalPaxName"
                                            name="airportFromArrival"
                                            as={TextField}
                                            variant="filled"
                                            InputProps={{
                                                inputProps: {
                                                    style: {
                                                        padding: '5px 10px',
                                                    },
                                                },
                                            }}
                                            sx={{
                                                '& .MuiFilledInput-root': {
                                                    borderBottom: 'none',
                                                },
                                                '& .MuiFilledInput-underline:before': {
                                                    borderBottom: 'none',
                                                },
                                                '& .MuiFilledInput-underline:after': {
                                                    borderBottom: 'none',
                                                },
                                            }}
                                        />
                                    </div>
                                    <ErrorMessage name="airportFromArrival" component="div" className="error" />
                                    <div className="other-pax">
                                        <p style={{ padding: "10px", paddingTop: "10px" }}>Arrival date</p>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                sx={{
                                                    width: {
                                                        xs: "50%",
                                                        sm: "50%",
                                                        md: "50%",
                                                    },
                                                    overflow: "hidden",
                                                    boxSizing: "border-box",
                                                }}
                                                components={['DatePicker']}
                                            >
                                                <DatePicker
                                                    label="Select date"
                                                    value={values.arrivalDate}
                                                    onChange={(val) => setFieldValue("arrivalDate", val)}

                                                    sx={{
                                                        backgroundColor: "rgb(228, 228, 228)",
                                                        width: {
                                                            xs: "100%",
                                                            sm: "100%",
                                                            md: "80%",
                                                        },
                                                        boxSizing: "border-box",
                                                        '& .MuiInputBase-root': {
                                                            height: {
                                                                xs: "40px",
                                                                sm: "43px",
                                                            },
                                                            width: {
                                                                xs: "17%",
                                                                sm: "100%",
                                                                md: "80%",
                                                            },
                                                            boxSizing: "border-box",
                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            padding: "5px",
                                                        },
                                                        '& .MuiFormLabel-root': {
                                                            fontSize: {
                                                                xs: "10px",
                                                                sm: "12px",
                                                            },
                                                        },
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                    <ErrorMessage name="arrivalDate" component="div" className="error" />

                                    <div className="airport-from">
                                        <p style={{ padding: "10px", paddingTop: "20px" }}>Arrival time</p>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                sx={{
                                                    width: {
                                                        xs: "50%",
                                                        sm: "50%",
                                                        md: "50%",
                                                    },
                                                    overflow: "hidden",
                                                    boxSizing: "border-box",
                                                }}
                                                components={['TimePicker']}
                                            >
                                                <TimePicker
                                                    value={values.arrivalTime}
                                                    label="Select time"
                                                    onChange={(val) => setFieldValue("arrivalTime", val)}
                                                    sx={{
                                                        backgroundColor: "rgb(228, 228, 228)",
                                                        width: {
                                                            xs: "100%",
                                                            sm: "100%",
                                                            md: "80%",
                                                        },
                                                        boxSizing: "border-box",
                                                        '& .MuiInputBase-root': {
                                                            height: {
                                                                xs: "40px",
                                                                sm: "43px",
                                                            },
                                                            width: {
                                                                xs: "100%",
                                                                sm: "100%",
                                                                md: "80%",
                                                            },
                                                            boxSizing: "border-box",
                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            padding: "5px",
                                                        },
                                                        '& .MuiFormLabel-root': {
                                                            fontSize: {
                                                                xs: "10px",
                                                                sm: "12px",
                                                            },
                                                        },
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>

                                    </div>
                                    <ErrorMessage name="arrivalTime" component="div" className="error" />

                                    <div className="other-pax">
                                        <p style={{ padding: "5px" }}>Flight number eg:BA0022</p>
                                        <Field
                                            className="arrivalPaxName"
                                            name="flightNumberArrival"
                                            as={TextField}
                                            variant="filled"
                                            InputProps={{
                                                inputProps: {
                                                    style: {
                                                        padding: '5px 10px',
                                                    },
                                                },
                                            }}
                                            sx={{
                                                '& .MuiFilledInput-root': {
                                                    borderBottom: 'none',
                                                },
                                                '& .MuiFilledInput-underline:before': {
                                                    borderBottom: 'none',
                                                },
                                                '& .MuiFilledInput-underline:after': {
                                                    borderBottom: 'none',
                                                },
                                            }}
                                        />

                                    </div>
                                    <ErrorMessage name="flightNumberArrival" component="div" className="error" />

                                    <div className="no-of-pax">
                                        <p style={{ padding: "5px" }}>Cabin suitcase(s) QTY</p>

                                        <FormControl sx={{ width: "50%", height: "30px" }}>
                                            <Field className="paxArrival" name="cabinSuitcaseQtyArrival" as={Select} displayEmpty>
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                                <MenuItem value={6}>6</MenuItem>
                                                <MenuItem value={7}>7</MenuItem>
                                                <MenuItem value={8}>8</MenuItem>
                                                <MenuItem value={9}>9</MenuItem>
                                            </Field>
                                        </FormControl>
                                    </div>
                                    <ErrorMessage name="cabinSuitcaseQtyArrival" component="div" className="error" />

                                    <div className="other-pax">
                                        <p style={{ padding: "5px" }}>Hold luggage suitcase(s) QTY</p>
                                        <FormControl sx={{ width: "68%", height: "30px" }}>
                                            <Field className="paxArrival" name="holdLuggageQtyArrival" as={Select} displayEmpty>
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                                <MenuItem value={6}>6</MenuItem>
                                                <MenuItem value={7}>7</MenuItem>
                                                <MenuItem value={8}>8</MenuItem>
                                                <MenuItem value={9}>9</MenuItem>
                                            </Field>
                                        </FormControl>

                                    </div>
                                    <ErrorMessage name="holdLuggageQtyArrival" component="div" className="error" />

                                </div>

                                <div className="departure-main-div">
                                    <div className="departure-div">
                                        Departure
                                    </div>
                                    <div className="no-of-pax">
                                        <p style={{ padding: "5px" }}>No of Pax</p>
                                        <FormControl sx={{ width: "50%", height: "30px" }}>
                                            <Field className="paxArrival" name="noOfPaxDeparture" as={Select} displayEmpty>
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                                <MenuItem value={6}>6</MenuItem>
                                                <MenuItem value={7}>7</MenuItem>
                                                <MenuItem value={8}>8</MenuItem>
                                                <MenuItem value={9}>9</MenuItem>
                                            </Field>
                                        </FormControl>
                                    </div>
                                    <ErrorMessage name="noOfPaxDeparture" component="div" className="error" />

                                    <div className="other-pax">
                                        <p style={{ padding: "5px" }}>Other pax names</p>
                                        <Field
                                            className="arrivalPaxName"
                                            name="otherPaxNamesDeparture"
                                            as={TextField}
                                            variant="filled"
                                            InputProps={{
                                                inputProps: {
                                                    style: {
                                                        padding: '5px 10px',
                                                    },
                                                },
                                            }}
                                            sx={{
                                                '& .MuiFilledInput-root': {
                                                    borderBottom: 'none',
                                                },
                                                '& .MuiFilledInput-underline:before': {
                                                    borderBottom: 'none',
                                                },
                                                '& .MuiFilledInput-underline:after': {
                                                    borderBottom: 'none',
                                                },
                                            }}
                                        />

                                    </div>
                                    <ErrorMessage name="otherPaxNamesDeparture" component="div" className="error" />

                                    <div className="airport-from">
                                        <p style={{ padding: "5px" }}>Airport from eg:LHR</p>
                                        <Field
                                            className="arrivalPaxName"
                                            name="airportFromDeparture"
                                            as={TextField}
                                            variant="filled"
                                            InputProps={{
                                                inputProps: {
                                                    style: {
                                                        padding: '5px 10px',
                                                    },
                                                },
                                            }}
                                            sx={{
                                                '& .MuiFilledInput-root': {
                                                    borderBottom: 'none',
                                                },
                                                '& .MuiFilledInput-underline:before': {
                                                    borderBottom: 'none',
                                                },
                                                '& .MuiFilledInput-underline:after': {
                                                    borderBottom: 'none',
                                                },
                                            }}
                                        />

                                    </div>
                                    <ErrorMessage name="airportFromDeparture" component="div" className="error" />

                                    <div className="other-pax">
                                        <p style={{ padding: "10px", paddingTop: "20px" }}>Departure date</p>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                sx={{
                                                    width: {
                                                        xs: "50%",
                                                        sm: "50%",
                                                        md: "50%",
                                                    },
                                                    overflow: "hidden",
                                                    boxSizing: "border-box",
                                                }}
                                                components={['DatePicker']}
                                            >
                                                <DatePicker
                                                    label="Select date"
                                                    value={values.departureDate}
                                                    onChange={(val) => setFieldValue("departureDate", val)}

                                                    sx={{
                                                        backgroundColor: "rgb(228, 228, 228)",
                                                        width: {
                                                            xs: "100%",
                                                            sm: "100%",
                                                            md: "80%",
                                                        },
                                                        boxSizing: "border-box",
                                                        '& .MuiInputBase-root': {
                                                            height: {
                                                                xs: "40px",
                                                                sm: "43px",
                                                            },
                                                            width: {
                                                                xs: "100%",
                                                                sm: "100%",
                                                                md: "80%",
                                                            },
                                                            boxSizing: "border-box",
                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            padding: "5px",
                                                        },
                                                        '& .MuiFormLabel-root': {
                                                            fontSize: {
                                                                xs: "10px",
                                                                sm: "12px",
                                                            },
                                                        },
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                    <ErrorMessage name="departureDate" component="div" className="error" />


                                    <div className="airport-from">
                                        <p style={{ padding: "10px", paddingTop: "20px" }}>Departure time</p>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                sx={{
                                                    width: {
                                                        xs: "50%",
                                                        sm: "50%",
                                                        md: "50%",
                                                    },
                                                    overflow: "hidden",
                                                    boxSizing: "border-box",
                                                }}
                                                components={['TimePicker']}
                                            >
                                                <TimePicker
                                                    value={values.departureTime}
                                                    label="Select time"
                                                    onChange={(val) => setFieldValue("departureTime", val)}
                                                    sx={{
                                                        backgroundColor: "rgb(228, 228, 228)",
                                                        width: {
                                                            xs: "100%",
                                                            sm: "100%",
                                                            md: "80%",
                                                        },
                                                        boxSizing: "border-box",
                                                        '& .MuiInputBase-root': {
                                                            height: {
                                                                xs: "40px",
                                                                sm: "43px",
                                                            },
                                                            width: {
                                                                xs: "100%",
                                                                sm: "100%",
                                                                md: "80%",
                                                            },
                                                            boxSizing: "border-box",
                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            padding: "5px",
                                                        },
                                                        '& .MuiFormLabel-root': {
                                                            fontSize: {
                                                                xs: "10px",
                                                                sm: "12px",
                                                            },
                                                        },
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>

                                    </div>
                                    <ErrorMessage name="departureTime" component="div" className="error" />

                                    <div className="other-pax">
                                        <p style={{ padding: "5px" }}>Flight number eg:BA0022</p>
                                        <Field
                                            className="arrivalPaxName"
                                            name="flightNumberDeparture"
                                            as={TextField}
                                            variant="filled"
                                            InputProps={{
                                                inputProps: {
                                                    style: {
                                                        padding: '5px 10px',
                                                    },
                                                },
                                            }}
                                            sx={{
                                                '& .MuiFilledInput-root': {
                                                    borderBottom: 'none',
                                                },
                                                '& .MuiFilledInput-underline:before': {
                                                    borderBottom: 'none',
                                                },
                                                '& .MuiFilledInput-underline:after': {
                                                    borderBottom: 'none',
                                                },
                                            }}
                                        />

                                    </div>
                                    <ErrorMessage name="flightNumberDeparture" component="div" className="error" />

                                    <div className="no-of-pax">
                                        <p style={{ padding: "5px" }}>Cabin suitcase(s) QTY</p>
                                        <FormControl sx={{ width: "50%", height: "30px" }}>
                                            <Field className="paxArrival" name="cabinSuitcaseQtyDeparture" as={Select} displayEmpty>
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                                <MenuItem value={6}>6</MenuItem>
                                                <MenuItem value={7}>7</MenuItem>
                                                <MenuItem value={8}>8</MenuItem>
                                                <MenuItem value={9}>9</MenuItem>
                                            </Field>
                                        </FormControl>
                                    </div>
                                    <ErrorMessage name="cabinSuitcaseQtyDeparture" component="div" className="error" />

                                    <div className="other-pax">
                                        <p style={{ padding: "5px" }}>Hold luggage suitcase(s) QTY</p>
                                        <FormControl sx={{ width: "68%", height: "30px" }}>
                                            <Field className="paxArrival" name="holdLuggageQtyDeparture" as={Select} displayEmpty>
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                                <MenuItem value={6}>6</MenuItem>
                                                <MenuItem value={7}>7</MenuItem>
                                                <MenuItem value={8}>8</MenuItem>
                                                <MenuItem value={9}>9</MenuItem>

                                            </Field>
                                        </FormControl>

                                    </div>
                                    <ErrorMessage name="holdLuggageQtyDeparture" component="div" className="error" />
                                </div>
                                <div className="select-options-div">
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="transfer-options"
                                            name="transferOption"
                                            value={values.transferOption}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value="I would like a complimentary transfer" control={<Radio />} label="I would like a complimentary transfer" />
                                            <FormControlLabel value="I do not require a complimentary airport transfer" control={<Radio />} label="I do not require a complimentary airport transfer" />
                                            <FormControlLabel value="I would like to pay for a private transfer. Private transfers are €280 for upto 3 passengers and GHI will contact you with a booking form" control={<Radio />} label="I would like to pay for a private transfer. Private transfers are €280 for upto 3 passengers and GHI will contact you with a booking form" />
                                        </RadioGroup>
                                    </FormControl>
                                    <ErrorMessage name="transferOption" component="div" className="error" />
                                </div>
                                <div className="save-button">
                                    <Button sx={{ width: "50%" }} type="submit" variant="contained">Save</Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </LocalizationProvider>

        </>
    );
};

export default ArrivalDeparture;
