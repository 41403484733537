import { AnyAaaaRecord } from "dns";
import axiosInstance from "./Axios";

export class ProfileApiServices {
  static getProfile = async () => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `web/users/profile`,
      });

      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static getNotifications = async () => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `web/users/notification-record`,
      });

      return {
        data: res.data?.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static markNotification = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: `web/users/notifications/read`,
        data,
      });

      return {
        data: res,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static storeFcmToken = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: `web/users/fcm-token`,
        data,
      });

      return {
        data: res.data.data
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static sendMessage = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: `web/users/send-message`,
        data,
      });

      return {
        data: res.data.data
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static sendRequestorMessage = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: `web/users/send-requestor-message`,
        data,
      });
      return {
        data: res
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static createNotification = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: `web/users/notifications`,
        data,
      });

      return {
        data: res.data.data
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static replyMessage = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: `web/users/reply-message`,
        data,
      });

      return {
        data: res
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static updateNotification = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "patch",
        url: `web/users/notifications`,
        data,
      });

      return {
        data: res.data.data
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static getNotificationSetting = async () => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `web/users/notifications`,
      });

      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static editDelegate = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: `web/users/profile/update`,
        data,
      });

      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static getCompanies = async () => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `/web/companies`,

        params: {
          page: 1,
          limit: 100,
          search: "",
          created_at: "",
        },
      });

      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static getCategory = async () => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `/web/industries`,

        params: {
          page: 1,
          limit: 100,
          search: "",
        },
      });

      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static updateProfileImage = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: `/web/users/profile/update-profile-image`,
        data,
      });

      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static createAirportTransfer = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: `web/users/airport-transfer`,
        data,
      });

      return {
        data: res.data.data
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static getDelegateId = async (email: any) => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `web/users/delegate-data`,
        params: {
          email
        },
      });

      return {
        data: res.data.data
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

}
